<template>
  <div class="content_box">
    <el-card>
      <div slot="header" class="clearfix">
        <span>新增采购订单</span>
      </div>
      <el-row>
        <el-col :span="12">
          <span>备注:</span>
          <el-input
            type="textarea"
            v-model="remark"
            :rows="1"
            :maxlength="500"
            show-word-limit
            style="width: 100%; margin-top: 10px"
          ></el-input>
        </el-col>
        <el-col :span="12">
          <el-button
            type="success"
            @click="addFormDialogVisabled = true"
            style="float: right"
            >新增商品</el-button
          >
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-table :data="productList" border height="600">
          <el-table-column label="#" type="index"></el-table-column>
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <el-image
                fit="contain"
                style="width: 50px; height: 50px"
                :src="scope.row.thumbImage"
                :preview-src-list="[scope.row.thumbImage]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="productName"
            label="商品名称"
          ></el-table-column>
          <el-table-column prop="modelName" label="商品型号"></el-table-column>
          <el-table-column
            prop="productBrandName"
            label="商品品牌"
          ></el-table-column>

          <el-table-column
            prop="productTypeName"
            label="商品类型"
          ></el-table-column>
          <el-table-column prop="amount" label="商品数量"></el-table-column>
          <el-table-column prop="price" label="商品价格"></el-table-column>
          <el-table-column label="编辑">
            <template slot-scope="scope">
              <el-button
                @click="openEditDialog(scope.row)"
                type="primary"
                icon="el-icon-edit"
                circle
              ></el-button>
              <el-button
                type="danger"
                @click="deleteProduct(scope.row.id)"
                icon="el-icon-delete"
                circle
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row style="margin-top: 20px">
        <div style="float: right">
          <el-button type="primary" @click="orderSubmit">保存订单</el-button>
          <el-button type="info" @click="cancelOrder">取消订单</el-button>
        </div>
      </el-row>
    </el-card>

    <!-- 新增商品对话框 -->
    <el-dialog
      title="新增商品"
      @close="resetForm('addProductForm')"
      :visible.sync="addFormDialogVisabled"
    >
      <el-form
        ref="addProductForm"
        :rules="FormRules"
        :model="addForm"
        label-width="120px"
      >
        <el-form-item prop="productBrandId" label="商品品牌">
          <el-select
            @change="getaddFormProductOptions"
            filterable
            @focus="onFocus"
            v-model="addForm.productBrandId"
            placeholder="请选择商品品牌"
          >
            <el-option
              v-for="item in productBrandOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="productTypeId" label="商品类别">
          <el-select
            @change="getaddFormProductOptions"
            filterable
            @focus="onFocus"
            v-model="addForm.productTypeId"
            placeholder="请选择商品类别"
          >
            <el-option
              v-for="item in productTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="productId" label="商品">
          <el-select
            filterable
            @focus="onFocus"
            allow-create
            v-model="addForm.productId"
            placeholder="请选择商品"
          >
            <el-option
              v-for="item in addFormProductOptions"
              :key="item.id"
              :label="item.productName + ' ' + item.modelName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="addNowSelectProduct" label="商品图片">
          <el-image
            fit="contain"
            style="width: 100px; height: 100px"
            :src="addNowSelectProduct.thumbImage"
            :preview-src-list="[addNowSelectProduct.thumbImage]"
          >
          </el-image>
        </el-form-item>
        <el-form-item prop="amount" label="数量">
          <el-input
            type="text"
            v-model="addForm.amount"
            placeholder="请输入商品数量"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="price" label="价格">
          <el-input
            type="text"
            v-model="addForm.price"
            placeholder="请输入商品价格"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addFormDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="addProductConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑商品对话框 -->
    <el-dialog title="编辑商品" :visible.sync="editFormDialogVisabled">
      <el-form
        ref="editProductForm"
        :rules="FormRules"
        :model="editForm"
        label-width="120px"
      >
        <el-form-item label="商品品牌">
          <el-select
            disabled
            filterable
            @focus="onFocus"
            v-model="editForm.productBrandId"
            placeholder="请选择商品品牌"
          >
            <el-option
              v-for="item in productBrandOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品类别">
          <el-select
            disabled
            filterable
            @focus="onFocus"
            v-model="editForm.productTypeId"
            placeholder="请选择商品类别"
          >
            <el-option
              v-for="item in productTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品">
          <el-input
            disabled
            type="text"
            :value="editForm.productName + ' ' + editForm.modelName"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品图片">
          <el-image
            fit="contain"
            style="width: 100px; height: 100px"
            :src="editForm.thumbImage"
            :preview-src-list="[editForm.thumbImage]"
          >
          </el-image>
        </el-form-item>
        <el-form-item prop="amount" label="数量">
          <el-input
            type="text"
            v-model="editForm.amount"
            placeholder="请输入商品数量"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="price" label="价格">
          <el-input
            type="text"
            v-model="editForm.price"
            placeholder="请输入商品价格"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="editProductConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getProductById,
  getProduct,
  getProductBrand,
  getProductType,
} from '@/api/product.js'
import { addProcurementOrder } from '@/api/procurementOrder.js'
import { validateNumber } from '@/utils/validate.js'
import { iosOnFocus } from '@/utils/iosPlatform.js'

export default {
  data() {
    let validateNum = (rule, value, callback) => {
      if (validateNumber(value)) {
        callback()
      } else {
        callback(new Error('当前类型为非数字类型'))
      }
    }
    return {
      remark: '',
      // 新增商品对话框显示
      addFormDialogVisabled: false,
      // 新增商品表单
      addForm: {
        productId: null,
        productBrandId: null,
        productTypeId: null,
        amount: 1,
        price: 0,
      },
      FormRules: {
        productId: [
          {
            required: true,
            message: '请选择商品',
            trigger: 'blur',
          },
        ],
        productBrandId: [
          {
            required: true,
            message: '请选择商品品牌',
            trigger: 'blur',
          },
        ],
        productTypeId: [
          {
            required: true,
            message: '请选择商品类型',
            trigger: 'blur',
          },
        ],
        amount: [
          {
            required: true,
            message: '请输入商品数量 ',
            trigger: 'blur',
          },
          {
            validator: validateNum,
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入商品价格',
            trigger: 'blur',
          },
          {
            validator: validateNum,
            trigger: 'blur',
          },
        ],
      },
      // 编辑商品对话框显示
      editFormDialogVisabled: false,
      // 编辑商品表单
      editForm: {},
      // 当前订单商品列表
      productList: [],
      // 当前商品品牌配置
      productBrandOptions: [],
      // 当前商品类型配置
      productTypeOptions: [],
      addFormProductOptions: [],
    }
  },
  methods: {
    async getProduct() {
      let { data } = await getProductById(this.productId)
    },
    async getProductOption(brandId, typeId) {
      let { data } = await getProduct(brandId, typeId)
      return data
    },
    async getProductBrandOption() {
      let { data } = await getProductBrand()
      this.productBrandOptions = data
    },
    async getProductTypeOption() {
      let { data } = await getProductType()
      this.productTypeOptions = data
    },
    async getaddFormProductOptions(val) {
      this.addForm.productId = null
      this.addFormProductOptions = []
      if (!this.addForm.productBrandId || !this.addForm.productTypeId) {
        return
      }
      let { data } = await getProduct(
        this.addForm.productBrandId,
        this.addForm.productTypeId
      )
      this.addFormProductOptions = data
    },
    addProductConfirm() {
      this.$refs.addProductForm.validate((valid) => {
        if (valid) {
          let product = this.addFormProductOptions.filter((item) => {
            return item.id == this.addForm.productId
          })[0]

          let amount = parseInt(this.addForm.amount)
          let price = parseFloat(this.addForm.price)
          let addProduct = { ...product, amount, price }

          let hasArr = this.productList.filter((item) => {
            return item.id == addProduct.id && item.price == addProduct.price
          })

          if (hasArr.length == 0) {
            this.productList.push(addProduct)
          } else {
            hasArr[0].amount += amount
            hasArr[0].price = price
          }

          this.addFormDialogVisabled = false

          this.$message.success('商品添加成功')
        } else {
          this.$message.error('新增数据验证失败')
        }
      })
    },
    // 从采购订单中删除商品
    deleteProduct(productId) {
      this.$confirm('当前操作将会将商品从采购订单中删除是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.productList = this.productList.filter((item) => {
            return item.id != productId
          })

          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    openEditDialog(data) {
      this.editForm = { ...data }
      this.editFormDialogVisabled = true
    },
    // 编辑商品
    editProductConfirm() {
      this.$refs.editProductForm.validate((valid) => {
        if (valid) {
          this.productList = this.productList.filter((item) => {
            return item.id != this.editForm.id
          })

          console.log(this.editForm)

          this.editForm.amount = parseInt(this.editForm.amount)
          this.editForm.price = parseFloat(this.editForm.price)

          this.productList.push(this.editForm)

          this.editFormDialogVisabled = false

          this.$message.success('编辑商品成功')
        } else {
          this.$message.error('编辑商品验证信息失败')
        }
      })
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 提交采购订单
    async orderSubmit() {
      let list = this.productList.map((item) => {
        return { productId: item.id, amount: item.amount, price: item.price }
      })

      let { data } = await addProcurementOrder({
        remark: this.remark,
        procurementList: list,
      })

      this.$router.push({ path: '/Purchase' })
    },
    cancelOrder() {
      this.$router.push({ path: '/Purchase' })
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
  created() {
    this.getProductBrandOption()
    this.getProductTypeOption()
  },
  computed: {
    addNowSelectProduct() {
      if (!this.addForm.productId || !this.addFormProductOptions) {
        return null
      }
      let arr = this.addFormProductOptions.filter((item) => {
        return item.id == this.addForm.productId
      })

      if (arr.length == 0) {
        return null
      }

      return arr[0]
    },
  },
}
</script>
<style lang="scss" scoped>
.content_box {
  width: 100%;
  height: 100%;
  // background-color: white;
}
.el-form-item {
  margin-bottom: 20px;
}
.el-select {
  width: 100%;
}
</style>
